import * as React from "react";
import {
  ContentContainer,
  LoadingIndicator,
  PageTitle,
  RootContainer,
  TitleContainer,
} from "@components/ui/PageBaseComponents";
import DataTable, { TableColumn } from "react-data-table-component";
import { Store } from "@util/interfaces";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "@redux/index";
import { FcCheckmark } from "react-icons/fc";
import { IoMdClose } from "react-icons/io";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { Filters } from "./Filters";
import { normalizeString, toastError } from "@util/functions";
import { fetchStores, setStores, setStoresQuery } from "@redux/AppData.store";
import moment from "moment";
import { IoIosCheckmarkCircle } from "react-icons/all";
import InputMask from "react-input-mask";
import Api from "@services/Api";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";

const columns = (
  onBlurCallback: (store: Store, currentDate: string, fieldName: keyof Store) => void,
  onClickCallback: (store: Store, fieldName: keyof Store) => void,
) => {
  return [
    {
      name: "Ativo",
      center: true,
      selector: (row: Store) => row.is_active,
      format: (row: Store) => (row.is_active ? <FcCheckmark /> : <IoMdClose />),
      grow: 0,
    },
    {
      name: "Nome",
      center: true,
      selector: (row: Store) => normalizeString(row.name),
      sortable: true,
    },
    {
      name: "Plano",
      center: true,
      selector: (row: Store) => row.plan?.priority,
      format: (row: Store) => row.plan?.name,
      sortable: true,
    },
    {
      name: "Data da Live",
      center: true,
      cell: (row: Store) => {
        return (
          <>
            <InputMask
              mask={"99/99/9999"}
              style={{ border: "none", textAlign: "center", width: "80px", borderRadius: "6px", minHeight: "30px", marginRight: "4px" }}
              placeholder={"Sem Data"}
              defaultValue={row.last_live_date ? moment(row.last_live_date).format("DD/MM/YYYY") : ""}
              onBlur={(e) => onBlurCallback(row, e.target.value, "last_live_date")}
            ></InputMask>
            <div onClick={() => onClickCallback(row, "was_last_live_made")}>
              {row.was_last_live_made ? (
                <IoIosCheckmarkCircle color={"green"} size={20} />
              ) : (
                <IoIosCheckmarkCircle color={"gray"} size={20} />
              )}
            </div>
          </>
        );
      },
    },
    {
      name: "Data do Feed",
      center: true,
      cell: (row: Store) => {
        return (
          <>
            <InputMask
              mask={"99/99/9999"}
              style={{ border: "none", textAlign: "center", width: "80px", borderRadius: "6px", minHeight: "30px", marginRight: "4px" }}
              placeholder={"Sem Data"}
              defaultValue={row.last_feed_date ? moment(row.last_feed_date).format("DD/MM/YYYY") : ""}
              onBlur={(e) => onBlurCallback(row, e.target.value, "last_feed_date")}
            ></InputMask>
            <div onClick={() => onClickCallback(row, "was_last_feed_made")}>
              {row.was_last_feed_made ? (
                <IoIosCheckmarkCircle color={"green"} size={20} />
              ) : (
                <IoIosCheckmarkCircle color={"gray"} size={20} />
              )}
            </div>
          </>
        );
      },
    },
    {
      name: "Data do Reels",
      center: true,
      cell: (row: Store) => {
        return (
          <>
            <InputMask
              mask={"99/99/9999"}
              style={{ border: "none", textAlign: "center", width: "80px", borderRadius: "6px", minHeight: "30px", marginRight: "4px" }}
              placeholder={"Sem Data"}
              defaultValue={row.last_reels_date ? moment(row.last_reels_date).format("DD/MM/YYYY") : ""}
              onBlur={(e) => onBlurCallback(row, e.target.value, "last_reels_date")}
            ></InputMask>
            <div onClick={() => onClickCallback(row, "was_last_reels_made")}>
              {row.was_last_reels_made ? (
                <IoIosCheckmarkCircle color={"green"} size={20} />
              ) : (
                <IoIosCheckmarkCircle color={"gray"} size={20} />
              )}
            </div>
          </>
        );
      },
    },
    {
      name: "Data do Short",
      center: true,
      cell: (row: Store) => {
        return (
          <>
            <InputMask
              mask={"99/99/9999"}
              style={{ border: "none", textAlign: "center", width: "80px", borderRadius: "6px", minHeight: "30px", marginRight: "4px" }}
              placeholder={"Sem Data"}
              defaultValue={row.last_short_date ? moment(row.last_short_date).format("DD/MM/YYYY") : ""}
              onBlur={(e) => onBlurCallback(row, e.target.value, "last_short_date")}
            ></InputMask>
            <div onClick={() => onClickCallback(row, "was_last_short_made")}>
              {row.was_last_short_made ? (
                <IoIosCheckmarkCircle color={"green"} size={20} />
              ) : (
                <IoIosCheckmarkCircle color={"gray"} size={20} />
              )}
            </div>
          </>
        );
      },
    },
    {
      name: "Adimplente",
      center: true,
      cell: (row: Store) => {
        return (
        <div onClick={() => onClickCallback(row, "is_compliant")}>
              {row.is_compliant ? (
                <IoIosCheckmarkCircle color={"green"} size={20} />
              ) : (
                <IoIosCheckmarkCircle color={"red"} size={20} />
              )}
            </div>
        );
      },
    },
  ] as TableColumn<Store>[]
};



const StoresListScreen: React.FC = () => {
  const { stores, loadingStores, storesCount, storesQuery } = useSelector((state: RootState) => state.appSettings);

  const history = useHistory();
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(fetchStores());
    return () => {
      dispatch(setStores([]));
    };
    // eslint-disable-next-line
  }, [storesQuery]);

  const handlePageChange = (page: number) => {
    dispatch(setStoresQuery({ ...storesQuery, page: page }));
  };

  const handlePerRowsChange = async (newPerPage: number) => {
    dispatch(setStoresQuery({ ...storesQuery, take: newPerPage }));
  };

  const validateOnBlur = (store: Store, currentDate: string, fieldName: keyof Store) => {
    if (currentDate === moment(store[fieldName] as string).format("DD/MM/YYYY") || !currentDate) return;

    if (!moment(currentDate, "DD/MM/YYYY").isValid()) {
      toastError("Data inválida");
      return;
    }

    confirmAlert({
      title: "Atenção",
      message: "Deseja alterar esta data?",
      buttons: [
        {
          label: "Sim",
          onClick: async () => {
            try {
              Api.put(`/store/${store.id}`, { [fieldName]: currentDate }).then(() => {
                dispatch(fetchStores());
                toast("Data atualizada com sucesso", { type: "success" });
              });
            } catch (e) {
              toastError("Erro ao atualizar data");
              console.log(e);
            }
          },
        },
        {
          label: "Cancelar",

          onClick: () => {
            dispatch(fetchStores());
          },
        },
      ],
    });
  };

  const validateOnClick = (store: Store, field: keyof Store) => {
    confirmAlert({
      title: "Atenção",
      message: "Deseja alterar esse status?",
      buttons: [
        {
          label: "Sim",
          onClick: async () => {
            try {
              Api.put(`/store/${store.id}`, { [field]: !store[field] }).then(() => {
                dispatch(fetchStores());
                toast("Status atualizado com sucesso", { type: "success" });
              });
            } catch (e) {
              toastError("Erro ao atualizar status");
              console.log(e);
            }
          },
        },
        {
          label: "Cancelar",
          onClick: () => {},
        },
      ],
    });
  };

  return (
    <RootContainer>
      <TitleContainer>
        <PageTitle>Lojas</PageTitle>
        <Link to="/estabelecimentos/cadastro" className="btn btn-primary">
          cadastrar estabelecimento
        </Link>
      </TitleContainer>
      <ContentContainer>
        <div className={"p-2 text-muted"}>
          <h5>Filtros</h5>
        </div>
        <Filters />
        <div className={"p-2 mt-2 text-muted"}>
          <h5>Resultados</h5>
        </div>
        <DataTable
          noDataComponent="Nenhum resultado encontrado"
          progressComponent={<LoadingIndicator />}
          progressPending={loadingStores}
          columns={columns(validateOnBlur, validateOnClick)}
          data={stores}
          pagination
          paginationServer
          fixedHeader
          fixedHeaderScrollHeight="80vh"
          highlightOnHover
          pointerOnHover
          paginationTotalRows={storesCount}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          onRowClicked={(row: Store) => {
            history.push(`/estabelecimentos/${row.id}/editar`);
          }}
          customStyles={{
            headCells: {
              style: {
                fontSize: '14px', 
                fontWeight: 'bold',
                textAlign: 'center',
              },
            },
          }}
        />
      </ContentContainer>
    </RootContainer>
  );
};

export default StoresListScreen;
