import { ShortVideo } from "@util/interfaces";
import SortableList, { SortableItem } from "react-easy-sort";
import { BsTrash } from "react-icons/bs";
import styled from "styled-components";
import moment from "moment";
import { useState } from "react";
import { arrayMoveImmutable } from "array-move";
import Api from "@services/Api";
import { toastError, toastMessage } from "@util/functions";
import { ShortVideoModal } from "./components/ShortVideoModal";
import { EditShortVideoModal } from "./components/ShortVideoModal/edit";
import { MdEdit } from "react-icons/md";
import { confirmAlert } from "react-confirm-alert";

interface ShortVideosListProps {
  videos: ShortVideo[];
  shouldAddVideo?: boolean;
  shouldSort?: boolean;
  shouldEdit?: boolean;
  onAddVideo?: () => void;
}

const DeleteButton = styled.div`
  position: absolute;
  top: 10px;
  right: 5px;
  cursor: pointer;
  background-color: #f54242;
  padding: 3px 5px;
  border-radius: 6px;
  opacity: 0.7;
  :hover {
    opacity: 1;
  }
`;

const EditButton = styled.div`
  position: absolute;
  top: 50px;
  right: 5px;
  cursor: pointer;
  background-color: #2196f3;
  padding: 3px 5px;
  border-radius: 6px;
  opacity: 0.7;
  :hover {
    opacity: 1;
  }
`;

const PriorityIndicator = styled.div`
  position: absolute;
  top: 10px;
  left: 5px;
  padding: 3px 5px;
  border-radius: 6px;
  opacity: 0.7;
  :hover {
    opacity: 1;
  }
`;

export function ShortVideosList({
  videos,
  onAddVideo,
  shouldSort = true,
  shouldAddVideo = true,
  shouldEdit = true,
}: ShortVideosListProps) {
  const [shortVideos, setShortVideos] = useState<ShortVideo[]>(videos);
  const [addingVideo, setAddingVideo] = useState(false);
  const [editingVideo, setEditingVideo] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState<ShortVideo>();
  const [isHovered, setIsHovered] = useState(false);
  const [selectedStoreId, setSelectedStoreId] = useState<number>();

  const style = {
    backgroundColor: "#33AF50", // Alterar a cor de fundo no hover
    color: "white",
    borderRadius: "8px",
    paddingInline: 10,
    display: "flex",
    width: "fit-content",
    padding: "5px 10px",
    cursor: "pointer",
    marginBottom: 10,
    filter: isHovered ? "brightness(0.8)" : "brightness(1)", // Aplica o brilho no hover
    transition: "filter 0.3s ease", // Suaviza a transição do filtro
  };

  const onSortEnd = async (oldIndex: number, newIndex: number) => {
    if (!shouldSort) return;
    setShortVideos((array) => arrayMoveImmutable(array, oldIndex, newIndex));
    const sortedVideos = arrayMoveImmutable(shortVideos, oldIndex, newIndex);
    if (sortedVideos !== shortVideos) {
      const prioritiesPayload = sortedVideos.map((video, index) => ({
        video_id: video.id,
        priority: index,
      }));
      try {
        await Api.put(`/short-video/update_priority`, { payload: prioritiesPayload });
        toastMessage("Ordem atualizada", "success");
      } catch (error) {
        toastError(error);
      }
    }
  };

  const handleProductVideoDelete = async (id: number) => {
    confirmAlert({
      title: "Atenção",
      message: "Deseja excluir este video? Esta ação não pode ser desfeita.",
      buttons: [
        {
          label: "Sim",
          onClick: async () => {
            try {
              await Api.delete(`/short-video/${id}`);
              toastMessage("Video Curto excluído", "success");
              setShortVideos((array) => array.filter((video) => video.id !== id));
            } catch (error) {
              toastError(error, "error");
            }
          },
        },
        {
          label: "Cancelar",
          onClick: () => {},
        },
      ],
    });
  };

  return (
    <>
      {shouldAddVideo && (
        <div
          style={style}
          onMouseEnter={() => setIsHovered(true)} // Ativa o hover
          onMouseLeave={() => setIsHovered(false)} // Desativa o hover
          onClick={() => setAddingVideo(true)}
        >
          Adicionar novo vídeo curto
        </div>
      )}
      <SortableList
        onSortEnd={onSortEnd}
        style={{
          display: "flex",
          userSelect: "none",
          flexWrap: "wrap",
          gap: 30,
          marginTop: 20,
          marginBottom: 20,
          width: "100%",
        }}
        draggedItemClassName={"dragged"}
      >
        {shortVideos &&
          shortVideos?.map((video, i) => (
            <SortableItem key={video.id}>
              <div
                className="d-flex relative"
                style={{
                  position: "relative",
                  boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
                  padding: 10,
                  paddingRight: 20,
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div
                  className="p-2"
                  style={{
                    position: "relative",
                    cursor: "grab",
                    userSelect: "none",
                    marginBottom: 10,
                  }}
                >
                  <video
                    style={{
                      maxHeight: 130,
                      maxWidth: 100,
                      backgroundColor: "black",
                      objectFit: "contain",
                    }}
                    className={"p-auto m-auto"}
                    src={video.file_url}
                  />
                </div>
                <DeleteButton
                  onClick={() => {
                    handleProductVideoDelete(video.id);
                  }}
                >
                  <BsTrash color={"white"} size={14} />
                </DeleteButton>
                {shouldEdit && (
                  <EditButton
                    onClick={() => {
                      setSelectedVideo(video);
                      setEditingVideo(true);
                    }}
                  >
                    <MdEdit color={"white"} size={14} />
                  </EditButton>
                )}
                <PriorityIndicator>{i + 1}˚</PriorityIndicator>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    fontSize: 14,
                  }}
                >
                  <div>Loja: {video?.store?.name}</div>
                  <div>Descrição: {video.description}</div>
                  <div>Curtidas: {video.likes_amount}</div>
                  <div>Início: {moment(video.start_date).format("DD/MM/YYYY HH:mm")}</div>
                  <div>Fim: {moment(video.end_date).format("DD/MM/YYYY HH:mm")}</div>
                </div>
              </div>
            </SortableItem>
          ))}
      </SortableList>
      {addingVideo && (
        <>
          <ShortVideoModal
            open={addingVideo}
            center
            onClose={() => {
              setAddingVideo(false);
              onAddVideo && onAddVideo();
            }}
            priority={shortVideos.length}
            setSelectedStoreIdCallback={(storeId) => setSelectedStoreId(storeId)}
          />
        </>
      )}
      {shouldEdit && editingVideo && selectedVideo && (
        <>
          <EditShortVideoModal
            open={editingVideo}
            video={selectedVideo}
            center
            onClose={() => {
              setEditingVideo(false);
              setSelectedVideo(undefined);
              onAddVideo && onAddVideo();
            }}
          />
        </>
      )}
    </>
  );
}
