export enum UserRole {
  ADMIN = "admin",
  SELLER = "seller",
  CLIENT = "client",
}

export enum AddressType {
  BLOCK = "block",
  STREET = "street",
}

export enum AddressBuildingType {
  STORE = "store",
  BOX = "box",
}

export enum SalePeriod {
  MONTHLY = "monthly",
  SEMESTERLY = "semesterly",
  YEARLY = "yearly",
}

export enum SaleType {
  FIRST_TIME = "first_time",
  RENEWAL = "renewal",
  UPGRADE = "upgrade",
}

export enum PaymentMethod {
  CREDIT_CARD = "credit_card",
  CASH = "cash",
  PIX = "pix",
  BOLETO = "boleto",
  BOOKLET = "booklet",
}

export enum StablishmentType {
  STORE = "store",
  KIOSK = "kiosk",
  RESTAURANT = "restaurant",
  HOTEL = "hotel",
}
export enum CreditCardBrands {
  VISA = "visa",
  MASTERCARD = "mastercard",
  DINERS = "diners",
  ELO = "elo",
  HIPER_ITAU = "hiper_itau",
  HIPERCARD = "hipercard",
  AMEX = "amex",
  AMERICAN_EXPRESS = "american_express",
  DISCOVER = "discover",
  AURA = "aura",
  JCB = "jcb",
}

export enum PaymentStatus {
  CREATED = "created",
  PAID = "paid",
  CANCELED = "canceled",
  ANALYSIS = "analysis",
  REJECTED = "rejected",
  EXPIRED = "expired",
}

export interface SalePayment {
  id: number;
  value: number;
  status: PaymentStatus;
  emepag_order_id?: string;
  expires_at: Date;
  paid_at?: Date;
  payment_method?: PaymentMethod;
  credit_card_brand?: CreditCardBrands;
  installment_count?: number;
  manually_set_paid?: boolean;
  created_at: Date;
  updated_at: Date;
  sale: Sale;
}

export interface Sale {
  id: number;
  plan: Plan;
  period: SalePeriod;
  type: SaleType;
  activated_at?: Date;
  expires_at?: Date;
  expired_at?: Date;
  store: Store;
  seller: User;
  payments: SalePayment[];
  value: number;
  observations?: string;
  created_at: Date;
  updated_at: Date;
}

export interface User {
  id: number;
  name: string;
  email: string;
  password?: string;
  role: UserRole;
  sales: Sale[];
  is_active?: boolean;
}
export interface AppData {
  searchText: string;
  favorites: number[];
}

export interface Plan {
  id: number;
  name: string;
  allow_cover_image: boolean;
  allow_logo_image: boolean;
  allow_product_images: boolean;
  product_images_limit: number;
  price_monthly: number;
  price_semesterly: number;
  price_yearly: number;
  priority: number;
}

export interface Excursion {
  id: number;
  name: string;
  city: string;
  address?: string;
  state: string;
  phone: string;
  is_phone_whatsapp: boolean;
  phone_whatsapp_url?: string;
  phone_2?: string;
  is_phone_2_whatsapp?: boolean;
  phone_2_whatsapp_url?: string;
  route?: string;
  location_lat?: number;
  location_lng?: number;
}

export interface Department {
  name: string;
  image_url: string;
  id: string;
}

export interface Segment {
  name: string;
  image_url: string;
  id: string;
  storesCount: number;
  department?: Department;
}

export interface Tag {
  id: number;
  name: string;
}

export interface Store {
  id: number;
  name: string;
  legal_name?: string;
  patrimony_code?: string;
  email: string;
  cpf: string;
  cnpj: string;
  instagram: string;
  site: string;
  accepts_card: boolean;
  has_delivery: boolean;
  female: boolean;
  male: boolean;
  plan: Plan;
  tags: string[];
  is_active: boolean;
  phone_1: Phone;
  phone_2?: Phone;
  logo_image?: Image;
  cover_image?: Image;
  product_images?: Image[];
  patrimonies: Patrimony[];
  categories: StoreCategory[];
  departments?: Department[];
  segments?: Segment[];
  tag?: Tag[];
  created_at?: Date;
  active_sale?: Sale;
  stablishment_type: StablishmentType;
  stablishment_custom_address?: string;
  catalog_code?: string;
  marketplace_url?: string;
  is_marketplace_active?: boolean;
  opening_time?: OpeningTime;
  lat?: number;
  lng?: number;

  last_live_date?: Date;
  was_last_live_made: boolean;
  last_feed_date?: Date;
  was_last_feed_made: boolean;
  last_reels_date?: Date;
  was_last_reels_made: boolean;
  last_short_date?: Date;
  was_last_short_made: boolean;
  is_compliant: boolean;
}

export interface Phone {
  id: number;
  number: string;
  is_whatsapp: boolean;
}

export interface Image {
  id: number;
  url: string;
  key: string;
  clickable_url?: string;
  is_desktop_banner?: boolean;
  is_mobile_banner?: boolean;
}

export interface Video {
  id: number;
  url: string;
  key: string;
  clickable_url?: string;
  is_desktop_banner?: boolean;
  is_mobile_banner?: boolean;
}

export enum ShortVideoPlatformEnum {
  bitmovin = "bitmovin",
}

export interface ShortVideo {
  id: number;
  platformId: string;
  platform: ShortVideoPlatformEnum;
  file_url: string;
  key: string;
  priority: number;
  description: string;
  button_url: string;
  button_text: string;
  likes_amount: number;
  store: Store;
  start_date: Date;
  end_date: Date;
  created_at: Date;
}

export interface Patrimony {
  id: number;
  block: string;
  address_type: AddressType;
  module: string;
  street: string;
  number: string;
  building_type: AddressBuildingType;
}

export interface Hotel {
  id?: number;
  name: string;
  phone: string;
  phone_2?: string;
  email: string;
  address?: string;
}

export interface StoreCategory {
  id: number;
  name: string;
}

export enum StorePlan {
  FREE = 1,
  STANDARD = 2,
  GOLD = 3,
  PLATINUM = 4,
}

export interface StoreFormValues {
  name?: string;
  legal_name?: string;
  patrimony_code?: string;
  email?: string;
  cpf?: string;
  cnpj?: string;
  instagram?: string;
  site?: string;
  accepts_card?: string | boolean;
  has_delivery?: string | boolean;
  female?: string | boolean;
  male?: string | boolean;
  plan?: number;
  is_active?: string | boolean;
  phone_1_is_whatsapp?: string | boolean;
  phone_1_number?: string;
  phone_2_is_whatsapp?: string | boolean;
  phone_2_number?: string;
  stablishment_type: string;
  patrimonies: Patrimony[];
  departments: Department[];
  segments: Segment[];
  tag: Tag[];
  stablishment_custom_address?: string;
  catalog_code?: string;
  marketplace_url?: string;
  is_marketplace_active?: string | boolean;
}

export interface SellerFormValues {
  name?: string;
  password?: string;
  email?: string;
}

export interface ExcursionFormValues {
  name?: string;
  state?: string;
  city?: string;
  address?: string;
  phone?: string;
  is_phone_whatsapp?: boolean;
  phone_whatsapp_url?: string;
  phone_2?: string;
  is_phone_2_whatsapp?: boolean;
  phone_2_whatsapp_url?: string;
  route?: string;
}

export interface HotelFormValues {
  name?: string;
  phone?: string;
  phone_2?: string;
  email?: string;
  address?: string;
}

export interface PlanFormValues {
  id?: number;
  name?: string;
  allow_cover_image?: boolean;
  allow_logo_image?: boolean;
  allow_product_images?: boolean;
  product_images_limit?: number;
  price_monthly?: number;
  price_semesterly?: number;
  price_yearly?: number;
}

export interface PlanSalesMetrics {
  id: number;
  name: string;
  sales_count: number;
}

export interface PlanStoresMetrics {
  id: number;
  name: string;
  stores_count: number;
}

export interface StoresQuery {
  name?: string;
  block?: string;
  address_type?: string;
  module?: string;
  street?: string;
  number?: string;
  building_type?: string;
  plan?: number;
  take?: number;
  page?: number;
  stablishment_type?: string;
  store_info_type?: string;
  store_info_value?: string;
}

export interface SalesQuery {
  payment_method?: string;
}

export interface SelectOption {
  value: any;
  label: string;
}

export interface OpeningTime {
  monday_open?: Date;
  monday_close?: Date;
  tuesday_open?: Date;
  tuesday_close?: Date;
  wednesday_open?: Date;
  wednesday_close?: Date;
  thursday_open?: Date;
  thursday_close?: Date;
  friday_open?: Date;
  friday_close?: Date;
  saturday_open?: Date;
  saturday_close?: Date;
  sunday_open?: Date;
  sunday_close?: Date;
}

export interface LiveOptions {
  id: number;
  is_on: boolean;
  video_id?: string;
  active_store?: Store;
}

export interface LocationCategory {
  id: number;
  name: string;
  description: string;
  color?: string;
  image_url?: string;
}

export interface Location {
  id: number;
  description?: string;
  lat: number;
  lng: number;
  name: string;
  url?: string;
  locationCategory?: LocationCategory;
}

export interface LocationsQuery {
  name?: string;
  location_category_id?: string;
}
