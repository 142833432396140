import { Video } from "@util/interfaces";
import * as React from "react";
import SortableList, { SortableItem } from "react-easy-sort";
import { arrayMoveImmutable } from "array-move";
import Api from "@services/Api";
import { toastError, toastMessage } from "@util/functions";
import { BsTrash } from "react-icons/bs";
import styled from "styled-components";
import { VideoUrlModal } from "./components/UrlModal";

interface VideosListProps {
  videos: Video[];
  showUrlButton?: boolean;
}

const DeleteButton = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  background-color: #f54242;
  padding: 3px 5px;
  border-radius: 6px;
  opacity: 0.7;
  :hover {
    opacity: 1;
  }
`;

const VideosList: React.FC<VideosListProps> = ({ videos, showUrlButton }) => {
  const [videosList, setVideosList] = React.useState(videos);
  const [isUrlModalOpen, setIsUrlModalOpen] = React.useState(false);
  const [selectedVideo, setSelectedVideo] = React.useState<Video | null>(null);

  const onSortEnd = async (oldIndex: number, newIndex: number) => {
    setVideosList((array) => arrayMoveImmutable(array, oldIndex, newIndex));
    const sortedVideos = arrayMoveImmutable(videosList, oldIndex, newIndex);
    if (sortedVideos !== videosList) {
      const prioritiesPayload = sortedVideos.map((video, index) => ({
        video_id: video.id,
        priority: index,
      }));
      try {
        await Api.put(`/video/update_priority`, { payload: prioritiesPayload });
        toastMessage("Ordem atualizada", "success");
      } catch (error) {
        toastError(error);
      }
    }
  };

  const handleProductVideoDelete = async (id: number) => {
    try {
      await Api.delete(`/video/${id}`);
      toastMessage("Video excluído", "success");
      setVideosList((array) => array.filter((video) => video.id !== id));
    } catch (error) {
      toastError(error, "error");
    }
  };

  React.useEffect(() => {
    setVideosList(videos);
  }, [videos]);

  React.useEffect(() => {
    if (selectedVideo) {
      setIsUrlModalOpen(true);
    }
  }, [selectedVideo]);

  return (
    <SortableList
      onSortEnd={onSortEnd}
      style={{
        display: "flex",
        flexWrap: "wrap",
        userSelect: "none",
        marginTop: 20,
        justifyContent: "space-evenly",
        width: "100%",
      }}
      draggedItemClassName={"dragged"}
    >
      {videosList.map((video, index) => (
        <SortableItem key={video.id}>
          <div
            style={{
              position: "relative",
              cursor: "grab",
              userSelect: "none",
              boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
              marginBottom: 10,
            }}
          >
            <DeleteButton
              onClick={() => {
                handleProductVideoDelete(video.id);
              }}
            >
              <BsTrash color={"white"} size={14} />
            </DeleteButton>
            <video
              style={{
                backgroundColor: "rgba(200,200,200,0.2)",
                height: 230,
                width: 200,
                objectFit: "contain",
                boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
              }}
              className={"p-auto m-auto"}
              src={video.url}
            />
            {showUrlButton && (
              <div
                className="mb-1"
                style={{
                  position: "absolute",
                  bottom: 0,
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <button
                  className="btn btn-link"
                  style={{ backgroundColor: "rgba(255,255,255, 0.8)" }}
                  onClick={() => {
                    setSelectedVideo(video);
                  }}
                >
                  <span>Alterar link</span>
                </button>
              </div>
            )}
          </div>
        </SortableItem>
      ))}
      {selectedVideo && (
        <VideoUrlModal
          open={isUrlModalOpen}
          center
          video={selectedVideo}
          onClose={() => {
            setSelectedVideo(null);
          }}
        />
      )}
    </SortableList>
  );
};

export default VideosList;
