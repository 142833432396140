import { PageTitle } from "@components/ui/PageBaseComponents";
import Api from "@services/Api";
import { toastError, toastMessage } from "@util/functions";
import { useState } from "react";
import Modal, { ModalProps } from "react-responsive-modal";
import { DateRange } from "react-date-range";
import { pt } from "date-fns/locale";
import moment from "moment";
import { ShortVideo } from "@util/interfaces";

export interface EditShortVideoModalProps extends ModalProps {
  video: ShortVideo;
}

export const EditShortVideoModal = (props: EditShortVideoModalProps) => {
  const { video, onClose } = props;
  const [isSaving, setIsSaving] = useState(false);

  const [shortVideoStartDate, setShortVideoStartDate] = useState(moment(video.start_date));
  const [shortVideoEndDate, setShortVideoEndDate] = useState(moment(video.end_date));
  const [description, setDescription] = useState(video.description);
  const [buttonText, setButtonText] = useState(video.button_text);
  const [buttonLink, setButtonLink] = useState(video.button_url);
  const [startTime, setStartTime] = useState(moment(video.start_date).format("HH:mm"));
  const [endTime, setEndTime] = useState(moment(video.end_date).format("HH:mm"));

  const handleSave = async () => {
    setIsSaving(true);
    try {
      const body = {
        description,
        button_text: buttonText,
        button_url: buttonLink,
        start_date: `${shortVideoStartDate.format("YYYY-MM-DD")} ${startTime}`,
        end_date: `${shortVideoEndDate.format("YYYY-MM-DD")} ${endTime}`,
      };

      await Api.put(`/short-video/${video.id}`, body);
      toastMessage("Vídeo Curto editado", "success");
      onClose();
    } catch (error) {
      toastError(error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleDateChange = (values: any) => {
    setShortVideoStartDate(moment(values.selection.startDate));
    setShortVideoEndDate(moment(values.selection.endDate));
  };

  const selectionRange = {
    startDate: shortVideoStartDate.toDate(),
    endDate: shortVideoEndDate.toDate(),
    key: "selection",
  };

  return (
    <Modal {...props}>
      <PageTitle>Insira o novo vídeo curto</PageTitle>
      <div
        style={{
          minWidth: 400,
          maxWidth: "90vw",
          marginTop: 20,
          display: "flex",
          flexDirection: "column",
          gap: "12px",
        }}
      >
        <input
          className="form-control"
          style={{ maxWidth: "80vw" }}
          type="text"
          placeholder="Descrição"
          defaultValue={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <input
          className="form-control"
          placeholder="Texto do botão"
          style={{ maxWidth: "80vw" }}
          type="text"
          defaultValue={buttonText}
          onChange={(e) => setButtonText(e.target.value)}
        />
        <input
          className="form-control"
          placeholder="Link do botão"
          style={{ maxWidth: "80vw" }}
          type="text"
          defaultValue={buttonLink}
          onChange={(e) => setButtonLink(e.target.value)}
        />
        <div style={{ display: "flex", flexDirection: "column", gap: "8px", alignItems: "center" }}>
          <span>Selecione o período para o vídeo ser exibido</span>
          <DateRange locale={pt} ranges={[selectionRange]} onChange={handleDateChange} rangeColors={["#9841e2"]} />
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
          <span>Hora de início</span>
          <input
            placeholder="Hora de início"
            className="form-control"
            style={{ maxWidth: "80vw" }}
            type="time"
            defaultValue={startTime}
            onChange={(e) => setStartTime(e.target.value)}
          />
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
          <span>Hora de término</span>
          <input
            placeholder="Hora de término"
            className="form-control"
            style={{ maxWidth: "80vw" }}
            type="time"
            defaultValue={endTime}
            onChange={(e) => setEndTime(e.target.value)}
          />
        </div>
        <button className="btn btn-primary mt-3" onClick={handleSave} disabled={isSaving}>
          {isSaving ? "Salvando" : "Salvar"}
        </button>
      </div>
    </Modal>
  );
};
